'use client';
import { ROUTES } from '@constants/routes.constants';
import { useEffect } from 'react';
import { ErrorPage } from '@ui/components';
import { useLogErrorToRollbar } from './providers';

export default function Error({ error }) {
  useLogErrorToRollbar({
    error,
    context: {
      route: window.location.pathname,
      userAgent: navigator.userAgent,
    },
  });

  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <ErrorPage
      title="OOOPS"
      type="500"
      message="Something went wrong! Please retry later or contact our support team"
      buttonText="Go to Main"
      href={ROUTES.root}
    />
  );
}
